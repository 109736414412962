let containerEl, iframeWrapperEl, playPauseButtonEl, nextButtonEl, previousButtonEl, playerCoverImgEl, playerTitleEl,
    playIconEl, pauseIconEl, currentTimeEl, totalTimeEl, progressEl, progressBarEl, progressBarHandleEl, trackLinkEl,
    spotifyIconEl, youtubeIconEl, soundcloudIconEl;
let iframeEl, SCWidget, youtubePlayer, spotifyController, spotifyIframeApi;
let allTracks = [], totalDurationSeconds, currentTimeSeconds, currentProgress, currentPlatform, currentItem, currentIndex = 0, isPlaying = false;
let progressInterval;

function initTrackSelectionListeners() {
    const selectTrackButtonsEl = document.querySelectorAll('.js__select-track');
    selectTrackButtonsEl.forEach((buttonEl) => {
        buttonEl.addEventListener('click', (event) => {
            const attachmentId = event.target.dataset.attachmentId;
            const currentItem = allTracks.find((embed) => embed.id == attachmentId);
            if(currentItem) {
                selectTrack(currentItem);
            }
        })
    });
}

function jumpToNextTrack() {
    if(currentIndex < allTracks.length - 1) {
        selectTrack(allTracks[currentIndex + 1]);
    } else {
        selectTrack(allTracks[0]);
    }
}

function jumpToPrevTrack() {
    if(currentIndex > 0) {
        selectTrack(allTracks[currentIndex - 1]);
    } else {
        selectTrack(allTracks[allTracks.length - 1]);
    }
}

function initPlayer() {
    const togglePlayPause = () => {
        if(currentPlatform === 'spotify') {
            spotifyController.togglePlay();
        } else if(currentPlatform === 'soundcloud') {
            SCWidget.toggle();
        } else if (currentPlatform === 'youtube') {
            if(isPlaying) {
                youtubePlayer.pauseVideo();
            } else {
                youtubePlayer.playVideo();
            }
        }

        isPlaying = !isPlaying;
        pauseIconEl.style.display = isPlaying ? 'block' : 'none';
        playIconEl.style.display = isPlaying ? 'none' : 'block';
    }

    const seekPosition = (e) => {
        let percentage = e.layerX / progressEl.clientWidth;
        let jumpToSeconds = percentage * totalDurationSeconds;
        if(currentPlatform === 'soundcloud') {
            SCWidget.seekTo(jumpToSeconds * 1000);
        } else if(currentPlatform === 'youtube') {
            youtubePlayer.seekTo(jumpToSeconds, true);
        } else if(currentPlatform === 'spotify') {
            spotifyController.seek(jumpToSeconds);
        }
    }

    playPauseButtonEl.addEventListener('click', togglePlayPause);
    nextButtonEl.addEventListener('click', jumpToNextTrack);
    previousButtonEl.addEventListener('click', jumpToPrevTrack);
    progressEl.addEventListener('click', seekPosition);
}

// this function gets called when API is ready to use
function initYouTubeTrack() {
    // create the global player from the specific iframe (#video)
    youtubePlayer = new YT.Player('hiddenIframe', {
        playerVars: {
            'disablekb': 1,
            'enablejsapi': 1,
            'origin': window.location.href
        }
    });
    initDurationAndProgress();
}

function initSpotifyTrack() {
    let callback = (EmbedController) => {
        spotifyController = EmbedController;
        spotifyController.play();
        spotifyController.addListener('playback_update', e => {
            if(!totalDurationSeconds) {
                totalDurationSeconds = parseInt(e.data.duration / 1000);
                initTotalDuration();
            }
            currentTimeSeconds = parseInt(e.data.position / 1000);
            calculateTimeAndProgress();
            jumpToNextTrackIfFinished();
        });
    };
    let options = {
        uri: currentItem.attachment.url,
    }
    spotifyIframeApi.createController(iframeEl, options, callback);
}

function jumpToNextTrackIfFinished() {
    if(isPlaying && totalDurationSeconds-1 <= currentTimeSeconds) {
        isPlaying = false;
        setTimeout(() => {
            jumpToNextTrack();
        }, 900)
    }
}

function initSoundCloudTrack() {
    iframeEl.allow = 'autoplay';
    iframeEl.src = iframeEl.src + '&auto_play=true';
    SCWidget = SC.Widget(iframeEl);
    initDurationAndProgress();
}

function initDurationAndProgress() {
    clearProgressInterval();
    progressInterval = setInterval(() => {
        if(totalDurationSeconds <= 0) {
            initTotalDuration();
        }
        calculateTimeAndProgress();
    }, 100);
}

function clearProgressInterval() {
    clearInterval(progressInterval);
}

function resetPlayer() {
    totalDurationSeconds = 0;
    currentTimeSeconds = 0;
    currentProgress = 0;
    totalTimeEl.innerText = '--:--';
    currentTimeEl.innerText = '00:00';
    progressBarEl.style.width = `0%`;
    progressBarHandleEl.style.left = '-.125rem';
}

function showCorrectIcon() {
    spotifyIconEl.style.display = 'none';
    youtubeIconEl.style.display = 'none';
    soundcloudIconEl.style.display = 'none';
    if(currentPlatform === 'spotify') {
        spotifyIconEl.style.display = 'block';
    } else if (currentPlatform === 'youtube') {
        youtubeIconEl.style.display = 'block';
    } else if (currentPlatform === 'soundcloud') {
        soundcloudIconEl.style.display = 'block';
    }
}

function initTotalDuration() {
    if (currentPlatform === 'soundcloud') {
        SCWidget.getDuration((duration) => {
            totalDurationSeconds = parseInt(duration/1000);
        })
    } else if (currentPlatform === 'youtube') {
        totalDurationSeconds = parseInt(youtubePlayer.getDuration());
    }
    totalTimeEl.innerText = getReadableTime(totalDurationSeconds);
}

function calculateTimeAndProgress() {
    if (currentPlatform === 'soundcloud') {
        SCWidget.getPosition((position) => {
            currentTimeSeconds = parseInt(position/1000);
        })
    } else if (currentPlatform === 'youtube') {
        currentTimeSeconds = parseInt(youtubePlayer.getCurrentTime());
    }
    currentProgress = totalDurationSeconds > 0 ? (currentTimeSeconds / totalDurationSeconds) * 100 : 0;
    currentTimeEl.innerText = getReadableTime(currentTimeSeconds);
    progressBarEl.style.width = `${currentProgress}%`;
    progressBarHandleEl.style.left = currentProgress > 1.5 ? `calc(${currentProgress}% - 0.5rem)` : '-.125rem';

    jumpToNextTrackIfFinished();
}

function getReadableTime(totalSeconds) {
    let hours = Math.floor(((totalSeconds % 31536000) % 86400) / 3600);
    let minutes = Math.floor((((totalSeconds % 31536000) % 86400) % 3600) / 60);
    let seconds = (((totalSeconds % 31536000) % 86400) % 3600) % 60;

    if(hours < 9) {
        hours = '0' + hours;
    }
    if(minutes < 9) {
        minutes = '0' + minutes;
    }
    if(seconds < 9) {
        seconds = '0' + seconds;
    }

    let finalString = `${minutes}:${seconds}`;
    if(totalDurationSeconds >= 4600) {
        finalString = `${hours}:${finalString}`;
    }

    return finalString;
}

function selectTrack(item, isInitialLoad = false) {
    isPlaying = false;
    currentItem = item;
    currentIndex = allTracks.findIndex((embed) => embed.id == item.id);
    currentPlatform = currentItem.attachment.platform;

    iframeWrapperEl.innerHTML = item.attachment.iframe_code;
    iframeEl = document.querySelector('#iframeWrapper > iframe');
    iframeEl.id = 'hiddenIframe';
    playerTitleEl.innerHTML = item.attachment.title;
    playerCoverImgEl.style.backgroundImage = `url("${item.attachment.preview_picture_url}")`;
    trackLinkEl.href = item.attachment.url;

    showCorrectIcon();
    clearProgressInterval();
    resetPlayer();

    if(currentPlatform === 'soundcloud') {
        initSoundCloudTrack();
        if(isInitialLoad) {
            setTimeout(() => {
                initSoundCloudTrack();
            }, 1000);
        } else {
            initSoundCloudTrack();
        }
    } else if (currentPlatform === 'spotify') {
        if(isInitialLoad) {
            setTimeout(() => {
                initSpotifyTrack();
            }, 1000);
        } else {
            initSpotifyTrack();
        }
    } else if (currentPlatform === 'youtube') {
        iframeEl.src = iframeEl.src + '&enablejsapi=1';
        if(isInitialLoad) {
            iframeEl.src = iframeEl.src.replace('autoplay=1', 'autoplay=0');
        }
        if(isInitialLoad) {
            setTimeout(() => {
                initYouTubeTrack();
            }, 1000);
        } else {
            initYouTubeTrack();
        }
    }

    if(!isInitialLoad) {
        pauseIconEl.style.display = 'block';
        playIconEl.style.display = 'none';
        isPlaying = true;
    }
}

export function initMusicFeed() {
    var youtubeScript= document.querySelector('#youtubeScript');
    if(youtubeScript) {
        youtubeScript.remove();
    }
    var soundCloudScript= document.querySelector('#soundCloudScript');
    if(soundCloudScript) {
        soundCloudScript.remove();
    }
    var spotifyScript= document.querySelector('#spotifyScript');
    if(spotifyScript) {
        spotifyScript.remove();
    }
    var youtubeScript= document.createElement('script');
    youtubeScript.src = "https://www.youtube.com/iframe_api";
    youtubeScript.id = "youtubeScript";
    document.head.appendChild(youtubeScript);
    var soundCloudScript= document.createElement('script');
    soundCloudScript.src = "https://w.soundcloud.com/player/api.js";
    soundCloudScript.id = "soundCloudScript";
    document.head.appendChild(soundCloudScript);

    window.onSpotifyIframeApiReady = (IFrameAPI) => {
        spotifyIframeApi = IFrameAPI;
    };

    setTimeout(() => {
        containerEl = document.querySelector('#container');
        iframeWrapperEl = document.querySelector('#iframeWrapper');
        playPauseButtonEl = document.querySelector('#playPauseButton');
        nextButtonEl = document.querySelector('#nextButton');
        previousButtonEl = document.querySelector('#previousButton');
        playerCoverImgEl = document.querySelector('#playerCoverImg');
        playerTitleEl = document.querySelector('#playerTitle');
        playIconEl = document.querySelector('#playPauseButton > .player__play-button__play');
        pauseIconEl = document.querySelector('#playPauseButton > .player__play-button__pause');
        currentTimeEl = document.querySelector('#currentTime');
        totalTimeEl = document.querySelector('#totalTime');
        progressEl = document.querySelector('#progress');
        progressBarEl = document.querySelector('#progressBar');
        progressBarHandleEl = document.querySelector('#progressBarHandle');
        trackLinkEl = document.querySelector('#trackLink');
        spotifyIconEl = document.querySelector('#spotifyIcon');
        youtubeIconEl = document.querySelector('#youtubeIcon');
        soundcloudIconEl = document.querySelector('#soundcloudIcon');

        let items = JSON.parse(containerEl.dataset.musicFeed);
        items.forEach( (item) => {
            item.attachments.forEach((attachment) => {
                let finalEm = {
                    id: attachment.id,
                    attachment: attachment,
                    author: item.team_member,
                    message: item
                }
                delete finalEm.message.attachments;
                allTracks.push(finalEm);
            });
        })

        // Init reactive buttons
        initTrackSelectionListeners();
        selectTrack(allTracks[0], true);
        initPlayer();
    }, 250);

    // Load scripts.
    var spotifyScript= document.createElement('script');
    spotifyScript.id = "spotifyScript";
    spotifyScript.src = "https://open.spotify.com/embed/iframe-api/v1";
    document.head.appendChild(spotifyScript);
}
