import {Swiper} from 'swiper';

// let spacingDesktop = (window.innerWidth - 40) /16 * 6;
let spacingDesktop = (window.innerWidth - 1.5 * 16 * 2) / 16 * 2 + 32;
let spacingTablet = (window.innerWidth - 1.5 * 8 * 2) / 8 * 2 + 32;
let spacingMobile = 32;

export function initSwiperTeam() {
    let teamSwiper = new Swiper('.swiper-container-team', {
        slidesOffsetBefore: spacingMobile,
        slidesPerView: 1.6,
        spaceBetween: 32,
        grabCursor: true,
        speed: 600,
        freeMode: true,
        disableOnInteraction: false,
        autoplay: {
            delay: 2400,
            stopOnLastSlide: true
        },
        breakpoints: {
            769: {
                slidesOffsetBefore: spacingTablet
            },
            1025: {
                slidesOffsetBefore: spacingDesktop
            }
        }
    })
}


