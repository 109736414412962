// File: transStandard.js
// Import Highway
import Tween from 'gsap';
import Highway from '@dogstudio/highway';
import { killScrollTriggers } from './home';

let transOverlay = document.getElementById('transitionOverlay');

class transStandard extends Highway.Transition {
    in({from, to, done}) {
        killScrollTriggers();
        // Reset Scroll
        window.scrollTo(0, 0);

        // Remove Old View
        from.remove();


        to.style.opacity = "0";

        Tween.fromTo(transOverlay, 0.35,
            {transform: 'translateY(0)'},
            {
                delay: .1,
                transform: 'translateY(-100vh)',
                onComplete: tweenMain
            }
        );

        function tweenMain() {
            transOverlay.style.display = "none";
            Tween.fromTo(to, 0.2,
                {opacity: 0, y: 48},
                {
                    opacity: 1,
                    y: 0,
                    onComplete: done
                }
            );
        }
    }

    out({from, done}) {
        transOverlay.style.display = "block";
        // Animation
        Tween.fromTo(from, 0.2,
            {opacity: 1, y: 0},
            {
                opacity: 0,
                y: -16
            }
        );

        Tween.fromTo(transOverlay, 0.35,
            {transform: 'translateY(100vh)', ease: "expo.out"},
            {
                transform: 'translateY(0)',
                onComplete: done
            }
        );
    }
}

export default transStandard;