// Import the main SCSS file as otherwise Vite does not detect changes to it...
import '../scss/main.scss';
import transStandard from './transStandard';
import transAdvanced from './transAdvanced';
import transSide from './transSide';
import homeToProject from './homeToProject';
import Highway from '@dogstudio/highway';
import 'lazysizes';
import { fadeInElements, iconAnimation, iconAnimationCleanup } from "./animations";
import { initSwiperTeam } from './carousel';
import { initMusicFeed } from './musicFeed';
import { initHours } from './hours';
import { swerveCards, startMarquee, startClientRow } from './home';
import { initNav, closeNav, updateNav, fixBody, releaseBody } from './nav';

let routerView;

window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

document.addEventListener("DOMContentLoaded", function() {
	routerView = document.getElementById('content').dataset.routerView;
	fadeInElements();
	initNav();
	getScript(routerView);
});

const H = new Highway.Core({
	transitions: {
		default: transAdvanced,
		contextual: {
			homeToProject: homeToProject,
			transSide: transSide,
			transStandard: transStandard
		}
	}
});

H.on('NAVIGATE_IN', ({to, trigger, location}) => {
	window.scrollTo(0, 0);
	getScript(to.view.dataset.routerView);
});

H.on('NAVIGATE_OUT', ({from, trigger, location}) => {
	fixBody();
	closeNav();
	iconAnimationCleanup();
});

H.on('NAVIGATE_END', ({to, from, trigger, location}) => {
	fadeInElements();
	releaseBody();
	updateNav();
});

function getScript(pageName) {
	switch (pageName) {
		case 'Home':
			startMarquee();
			startClientRow();
			swerveCards();
			return;
			case 'Hours':
				initHours();
				return;
		case 'About':
			initSwiperTeam();
			iconAnimation();
			return;
		case 'Listen':
			initMusicFeed();
			return;
	}
}
