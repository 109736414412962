import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let iconAnimations = [];

export function fadeInElements() {

    const elToFadeInFast = gsap.utils.toArray('.fade-in-fast');
    const elToFadeIn = gsap.utils.toArray('.fade-in');
    const elToFadeInDelay = gsap.utils.toArray('.fade-in-delay');

    const elToFadeInSlower = gsap.utils.toArray('.fade-in-slower');

    const elToFadeInSlow = gsap.utils.toArray('.fade-in-slow');

    const imgsAppear = gsap.utils.toArray('.img-appear');

    imgsAppear.forEach((img, i) => {
        const anim = gsap.fromTo(img, {autoAlpha: 0, y: 48, scale: .95}, {duration: .5, autoAlpha: 1, y: 0, scale: 1});
        ScrollTrigger.create({
            trigger: img,
            animation: anim,
            toggleActions: 'play none none none'
        });
    });

    elToFadeIn.forEach((el, i) => {
        const anim = gsap.fromTo(el, {autoAlpha: 0, y: 48}, {duration: .8, autoAlpha: 1, y: 0});
        ScrollTrigger.create({
            trigger: el,
            animation: anim,
            toggleActions: 'play none none none'
        });
    });

    elToFadeInFast.forEach((el, i) => {
        const anim = gsap.fromTo(el, {autoAlpha: 0, y: 24}, {duration: .4, autoAlpha: 1, y: 0});
        ScrollTrigger.create({
            trigger: el,
            animation: anim,
            toggleActions: 'play none none none'
        });
    });

    elToFadeInDelay.forEach((el, i) => {
        const anim = gsap.fromTo(el, {autoAlpha: 0, y: 24}, {duration: .8, autoAlpha: 1, y: 0, delay: .32});
        ScrollTrigger.create({
            trigger: el,
            animation: anim,
            toggleActions: 'play none none none'
        });
    });

    elToFadeInSlow.forEach((el, i) => {
        const anim = gsap.fromTo(el, {autoAlpha: 0}, {duration: .8, delay: 1, autoAlpha: 1});
        ScrollTrigger.create({
            trigger: el,
            animation: anim,
            toggleActions: 'play none none none'
        });
    });

    elToFadeInSlower.forEach((el, i) => {
        const anim = gsap.fromTo(el, {autoAlpha: 0}, {duration: .8, delay: .6, autoAlpha: 1});
        ScrollTrigger.create({
            trigger: el,
            animation: anim,
            toggleActions: 'play none none none'
        });
    });

    document.addEventListener('lazyloaded', function(e){
        ScrollTrigger.refresh();
    });
}

const defaultPosition =  {x: 20, y: 20, opacity: 0}
const positions = [
    [
        defaultPosition,
        {x: 4, y: 20},
        defaultPosition,
        defaultPosition,
        defaultPosition,
        {x: 36, y: 20},
        {x: 31.3, y: 30.8},
        {x: 20, y: 36},
        {x: 9.2, y: 30.8},
    ],
    [
        {x: 20, y: 13},
        {x: 4, y: 13},
        defaultPosition,
        defaultPosition,
        defaultPosition,
        {x: 36, y: 13},
        {x: 36, y: 27},
        {x: 20, y: 27},
        {x: 4, y: 27},
    ],
    [
        {x: 20, y: 12},
        {x: 20, y: 20},
        defaultPosition,
        defaultPosition,
        defaultPosition,
        defaultPosition,
        {x: 28, y: 20},
        {x: 20, y: 28},
        {x: 12, y: 20},
    ],
    [
        {x: 20, y: 4},
        {x: 4, y: 4},
        {x: 20, y: 20},
        {x: 36, y: 4},
        {x: 36, y: 36},
        {x: 4, y: 36},
        {x: 36, y: 20},
        {x: 20, y: 36},
        {x: 4, y: 20},
    ],
    [
        defaultPosition,
        {x: 4, y: 4},
        {x: 20, y: 20},
        {x: 36, y: 4},
        defaultPosition,
        {x: 4, y: 36},
        {x: 36, y: 20},
        defaultPosition,
        defaultPosition
    ],
    [
        {x: 20, y: 4},
        defaultPosition,
        {x: 20, y: 20},
        defaultPosition,
        {x: 36, y: 36},
        {x: 4, y: 36},
        defaultPosition,
        {x: 20, y: 36},
        defaultPosition,
    ],
    [
        {x: 20, y: 20},
        {x: 4, y: 20},
        {x: 9, y: 10},
        {x: 20, y: 4},
        {x: 30.3, y: 10},
        {x: 36, y: 20},
        {x: 31.3, y: 30.8},
        {x: 20, y: 36},
        {x: 9.2, y: 30.8},
    ],
    [
        {x: 20, y: 20, opacity: 0},
        {x: 13, y: 4},
        {x: 4, y: 14},
        {x: 27, y: 4},
        {x: 36, y: 14},
        {x: 29, y: 25},
        {x: 20, y: 36},
        {x: 20, y: 36, opacity: 0},
        {x: 11, y: 25},
    ],
    [
        {x: 4, y: 36},
        defaultPosition,
        {x: 20, y: 20},
        {x: 36, y: 36},
        defaultPosition,
        {x: 36, y: 4},
        {x: 4, y: 4},
        defaultPosition,
        defaultPosition,
    ],
    // [
    //     {x: 20, y: 4},
    //     {x: 4, y: 4},
    //     {x: 20, y: 20},
    //     {x: 36, y: 4},
    //     defaultPosition,
    //     {x: 4, y: 36},
    //     defaultPosition,
    //     defaultPosition,
    //     {x: 4, y: 20},
    // ],
    [
        {x: 4, y: 36, opacity: 0},
        {x: 20, y: 36, opacity: 0},
        {x: 20, y: 20, opacity: 0},
        {x: 36, y: 36, opacity: 0},
        {x: 36, y: 20, opacity: 0},
        {x: 36, y: 4, opacity: 0},
        defaultPosition,
        defaultPosition,
        defaultPosition,
        defaultPosition,
    ],
];

const graphPosition =  [
    {x: 4, y: 36},
    {x: 20, y: 36},
    {x: 20, y: 20},
    {x: 36, y: 36},
    {x: 36, y: 20},
    {x: 36, y: 4},
    defaultPosition,
    defaultPosition,
    defaultPosition,
]

export function iconAnimation() {
    const dots = document.querySelectorAll(".signage__el");
    const steps = document.querySelectorAll(".about-section__manifesto-rule");
    const signage = document.getElementById('signage');

    setTimeout(() => {

       steps.forEach((step, index) => {

            const tl = gsap.timeline({paused: true, scrollTrigger: {
                trigger: step,
                start: () => 'top-=134 center-=' + window.innerHeight * .06,
                end:  () => 'bottom-=48 center-=' + window.innerHeight * .06,
                toggleActions: 'play none none reverse',
                invalidateOnRefresh: true,
                onLeaveBack: () => {
                    // move signage to correct vertical position
                    return index > 0 && gsap.fromTo(signage, {
                        y: () => {
                            return step.getBoundingClientRect().top - step.offsetParent.getBoundingClientRect().top
                        },
                    },{
                        y: () => {
                            return index - 1 >= 0 ? steps[index - 1].getBoundingClientRect().top - steps[index - 1].offsetParent.getBoundingClientRect().top : 0
                        },
                        duration: .08,
                        ease: 'linear'
                    });
                },
                onEnter: () => {
                    return index > 0 && gsap.fromTo(signage, {
                        y: () => {
                            return index - 1 >= 0 ? steps[index - 1].getBoundingClientRect().top - steps[index - 1].offsetParent.getBoundingClientRect().top : 0
                        }
                    },{
                        y: () => {
                            return step.getBoundingClientRect().top - step.offsetParent.getBoundingClientRect().top
                        },
                        duration: .08,
                        ease: 'linear'
                    });
                }
            }});


           iconAnimations.push(tl);

           if(positions[index]) {
                dots.forEach((dot, dotIndex) => {
                    const position = positions[index][dotIndex];
                    const prevPosition = index - 1 >= 0 ? positions[index - 1][dotIndex] : positions[0][dotIndex];

                    tl.fromTo(dot, {
                        y: prevPosition.y,
                        x: prevPosition.x,
                        opacity: prevPosition.hasOwnProperty('opacity') ? prevPosition.opacity : 1,
                        duration: .32,
                        delay: .16
                    }, {
                        y: position.y,
                        x: position.x,
                        opacity: position.hasOwnProperty('opacity') ? position.opacity : 1,
                        duration: .32,
                        delay: .16
                    }, 0);

                    tl.fromTo(dot, {
                        opacity: prevPosition.hasOwnProperty('opacity') ? prevPosition.opacity : 1,
                        duration: .16,
                    }, {
                        opacity: position.hasOwnProperty('opacity') ? position.opacity : 1,
                        duration: .16,
                        delay: .16
                    }, 0);
                });

                if(index === 9) {
                    const graphTl = gsap.timeline({paused: true, scrollTrigger: {
                            trigger: step,
                            start: () => 'top-=134 center-=' + window.innerHeight * .06,
                            end: () => 'bottom-=48 center-=' + window.innerHeight * .06,
                            toggleActions: 'play none play reset',
                            invalidateOnRefresh: true,
                        }});
                    iconAnimations.push(graphTl);
                    dots.forEach((dot, dotIndex) => {
                        if(index === 9) {
                            graphTl.to(dot, {
                                y: graphPosition[dotIndex].y,
                                x: graphPosition[dotIndex].x,
                                opacity: 1,
                                duration: .32,
                                delay: dotIndex * 0.09
                            }, 0.64);
                        }
                    });
                }

                if(index === 6) {
                    const tlSpin = gsap.timeline({paused: true, scrollTrigger: {
                            trigger: step,
                            start: () => 'top-=134 center-=' + window.innerHeight * .06,
                            end: () => 'bottom-=48 center-=' + window.innerHeight * .06,
                            toggleActions: 'play reset play reset',
                        }});
                    iconAnimations.push(tlSpin);
                    tlSpin.to('#signage', {
                        rotation: 360 * 2,
                        duration: .68,
                        ease: 'power1x.inOut',
                        delay: .48
                    })
                }
            }
        });

       setTimeout(() => {
           // reset our dot formation
           dots.forEach((dot, dotIndex) => {
               const position = positions[0][dotIndex];
               gsap.to(dot, {
                   y: position.y,
                   x: position.x,
                   opacity: position.hasOwnProperty('opacity') ? position.opacity : 1,
                   duration: .32,
               });
           })

           gsap.set(signage, {
               y: steps[0].getBoundingClientRect().top - steps[0].offsetParent.getBoundingClientRect().top - 44,
               duration: 0,
               ease: 'linear',
               delay: 0
           })
       }, 500);
    }, 500); // allow browser to render first
}

export const iconAnimationCleanup = () => {
    iconAnimations.forEach(animation => {
        animation.kill();
    })
}
