import Tween from 'gsap';
import Highway from '@dogstudio/highway';
import { killScrollTriggers } from './home';

let transOverlay = document.getElementById('transitionOverlay');

class transSide extends Highway.Transition {
    in({from, to, done}) {
        killScrollTriggers();
        // Reset Scroll
        window.scrollTo(0, 0);

        // Remove Old View
        from.remove();

        to.style.opacity = "0";

        let fadeInEls = document.querySelectorAll('.fade-in, .fade-in-slower, .fade-in-slow, .fade-in-delay' );
        if (fadeInEls.length > 0) {
            fadeInEls.forEach((el, i) => {
                el.style.opacity = "0";
            });
        }

        Tween.fromTo(transOverlay, 0.35,
            {transform: 'translateX(0)'},
            {
                delay: .1,
                transform: 'translateX(-100vw)',
                onComplete: tweenMain
            }
        );

        function tweenMain() {
            transOverlay.style.display = "none";
            Tween.fromTo(to, 0.2,
                {opacity: 0},
                {
                    opacity: 1,
                    onComplete: done
                }
            );
        }
    }

    out({from, done}) {
        transOverlay.style.display = "block";
        // Animation
        Tween.fromTo(from, 0.2,
            {opacity: 1, x: 0},
            {
                opacity: 0,
                x: -16
            }
        );

        Tween.fromTo(transOverlay, 0.35,
            {transform: 'translateX(100vw)', ease: "expo.out"},
            {
                transform: 'translateX(0)',
                onComplete: done
            }
        );
    }
}

export default transSide;