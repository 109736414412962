// File: transStandard.js
// Import Highway
import { TweenLite } from "gsap";
import Highway from '@dogstudio/highway';
import { killScrollTriggers } from './home';

class homeToProject extends Highway.Transition {
    in({from, to, done}) {
        // Reset Scroll
        window.scrollTo(0, 0);

        // Remove Old View
        from.remove();
        killScrollTriggers();

        // Animation
        TweenLite.fromTo(to, .5,
            {opacity: 1},
            {
                opacity: 1,
                onComplete: removeCopy
            }
        );

        function removeCopy() {
            let stupidCopy = document.getElementById('stupidCopy')
            document.body.removeChild(stupidCopy);
            TweenLite.fromTo(to, .1,
                {opacity: 10},
                {
                    opacity: 1,
                    onComplete: done
                }
            );
        }
    }

    out({from, trigger, done}) {
        let projectCardImage = trigger.getElementsByTagName('img');
        let projectcardImageCopy = projectCardImage[0].cloneNode(true);
        document.body.appendChild(projectcardImageCopy);
        projectcardImageCopy.id = "stupidCopy";

        const x = projectCardImage[0].getBoundingClientRect().left;
        const y = projectCardImage[0].getBoundingClientRect().top;
        Object.assign(projectcardImageCopy.style,{position:"fixed",left:"0",top:"0",zIndex: 1,objectFit: 'cover',transform: `translate3d(${x}px, ${y}px, 0)`});
        TweenLite.to(projectcardImageCopy, .24,
            {
                x: 0,
                y: 0,
                scale: 1,
                width: '100%',
                height: '100%',
                onComplete: tweenMain
            }
        );

        function tweenMain() {
            TweenLite.fromTo(from, .32,
                {opacity: 1, y: 48},
                {
                    opacity: 1,
                    y: 0,
                    onComplete: done
                }
            );
        }
    }
}

export default homeToProject;