import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function startMarquee() {
    const containerElem = document.getElementById('marqueeContainer');
    const leftSideOfContainer = containerElem.getBoundingClientRect().left;
    const listElem = document.getElementById('marqueeInner');
    let currentLeftValue = 0;

    setTimeout(function(){
        window.setInterval(animationLoop, 3);
        }, 1000);

    function animationLoop() {
        const firstListItem = listElem.querySelector('.list__item:first-child');

        let rightSideOfFirstItem = firstListItem.getBoundingClientRect().right;

        if(rightSideOfFirstItem <= leftSideOfContainer) {
            currentLeftValue = -1;
            listElem.appendChild(firstListItem);
        }
        listElem.style.transform = "translateX(" + currentLeftValue + "px)";
        currentLeftValue--;
    }
}

export function startClientRow() {
    const containerElem = document.getElementById('marqueeClientContainer');
    const leftSideOfContainer = containerElem.getBoundingClientRect().left;
    const listElem = document.getElementById('marqueeClientInner');
    let currentLeftValue = 0;

    window.setInterval(animationLoop, 2);

    function animationLoop() {
        const firstListItem = listElem.querySelector('.list__item:first-child');

        let rightSideOfFirstItem = firstListItem.getBoundingClientRect().right;

        if(rightSideOfFirstItem <= leftSideOfContainer) {
            currentLeftValue = -1;
            listElem.appendChild(firstListItem);
        }
        listElem.style.transform = "translateX(" + currentLeftValue + "px)";
        currentLeftValue--;
    }
}

export function swerveCards() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".gsaptrigger",
            start: "top",
            end: "bottom",
            scrub: true
        }
    });
}

export function killScrollTriggers() {
    let triggers = ScrollTrigger.getAll();
    gsap.set(".gsap, .card-project", {clearProps: true});
    triggers.forEach( trigger => {
        trigger.kill(true);
    });
}