let logoWordmark = document.getElementsByClassName('logo-wordmark');
let logoBows = document.getElementsByClassName('logo-bow');
let navContainer = document.getElementsByTagName("nav")[0];
let navLinksBg = document.getElementsByClassName('nav-links')[0];
let menuItems = document.getElementsByClassName('nav-links-el');
let menuFooterLinks = document.getElementsByClassName('js-mob-menu-footer');
let nav = document.getElementById('navMob');
let handle = document.getElementById('navHandle');
let handles = document.getElementsByClassName('nav-handle__inner__line');
let passiveSupported = false;

export function initNav() {
    if (nav) {
        try {
            const options = {
                get passive() {
                    // This function will be called when the browser attempts to access the passive property.
                    passiveSupported = true;
                    return false;
                }
            };
        } catch (err) {
            passiveSupported = false;
        }

        handle.addEventListener("click", toggleMenu, passiveSupported
            ? {passive: true} : false);

        function toggleMenu() {
            if (nav.classList.contains('nav-links--open')) {
                document.body.style.overflow = "";
                nav.classList.remove('nav-links--open');
                handle.classList.remove('nav-handle--open');
                for (let i = 0; i < handles.length; i++) {
                    handles[i].classList.remove('nav-handle__inner__line--active');
                }
            } else {
                document.body.style.overflow = "hidden";
                nav.classList.add('nav-links--open');
                handle.classList.add('nav-handle--open');
                for (let i = 0; i < handles.length; i++) {
                    handles[i].classList.add('nav-handle__inner__line--active');
                }
            }
        }

        updateNav();
    }
}

export function updateNav() {
    if (nav) {
        let pageUID = document.getElementById('container');

        function setMenuItemActive() {
            let i = 0;

            for (i; i < menuItems.length; i++) {
                if (menuItems[i].dataset.id === pageUID.dataset.uid) {
                    menuItems[i].classList.add('nav-links-el--active');
                } else {
                    menuItems[i].classList.remove('nav-links-el--active');
                }
            }
        }

        function updateNavcolor() {
            if (pageUID.dataset.navcolor) {
                navContainer.style.backgroundColor = pageUID.dataset.navcolor;
                navLinksBg.style.backgroundColor = pageUID.dataset.navcolor;
                if (checkNavColorBrightness(pageUID.dataset.navcolor)) {
                    for (let i = 0; i < logoWordmark.length; i++) {
                        logoWordmark[i].style.fill = "#f7f7f7"
                    }
                    for (let i = 0; i < logoBows.length; i++) {
                        logoBows[i].style.fill = "#f7f7f7"
                    }
                    for (let i = 0; i < menuItems.length; i++) {
                        menuItems[i].style.color = "#f7f7f7"
                    }
                    for (let i = 0; i < handles.length; i++) {
                        handles[i].style.backgroundColor = "#f7f7f7"
                    }
                    for (let i = 0; i < menuFooterLinks.length; i++) {
                        menuFooterLinks[i].style.color = "#f7f7f7"
                    }
                } else {
                    for (let i = 0; i < logoWordmark.length; i++) {
                        logoWordmark[i].style.fill = ""
                    }
                    for (let i = 0; i < logoBows.length; i++) {
                        logoBows[i].style.fill = ""
                    }
                    for (let i = 0; i < menuItems.length; i++) {
                        menuItems[i].style.color = ""
                    }
                    for (let i = 0; i < handles.length; i++) {
                        handles[i].style.backgroundColor = ""
                    }
                    for (let i = 0; i < menuFooterLinks.length; i++) {
                        menuFooterLinks[i].style.color = ""
                    }
                }
            } else {
                navContainer.style.backgroundColor = "";
                navLinksBg.style.backgroundColor = "#f7f7f7";
                for (let i = 0; i < logoWordmark.length; i++) {
                    logoWordmark[i].style.fill = ""
                }
                for (let i = 0; i < logoBows.length; i++) {
                    logoBows[i].style.fill = ""
                }
                for (let i = 0; i < menuItems.length; i++) {
                    menuItems[i].style.color = ""
                }
                for (let i = 0; i < handles.length; i++) {
                    handles[i].style.backgroundColor = ""
                }
                for (let i = 0; i < menuFooterLinks.length; i++) {
                    menuFooterLinks[i].style.color = ""
                }
            }
        }

        function checkNavColorBrightness(bgColor) {
            var c = bgColor.substring(1);      // strip #
            var rgb = parseInt(c, 16);   // convert rrggbb to decimal
            var r = (rgb >> 16) & 0xff;  // extract red
            var g = (rgb >>  8) & 0xff;  // extract green
            var b = (rgb >>  0) & 0xff;  // extract blue

            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

            if (luma < 90) {
                return true
            } else {
                return false
            }
        }

        setMenuItemActive();
        updateNavcolor();
    }
}

export function closeNav() {
    if (nav.classList.contains('nav-links--open')) {
        window.scrollTo(0, 0);
        document.body.style.overflow = "";
        nav.classList.remove('nav-links--open');
        handle.classList.remove('nav-handle--open');
        for (let i = 0; i < handles.length; i++) {
            handles[i].classList.remove('nav-handle__inner__line--active');
        }
    }
}

export function fixBody() {
    document.body.style.overflow = "hidden"
}

export function releaseBody() {
    document.body.style.overflow = ""
}