export function initAccordions() {
    const accordionItems = document.querySelectorAll('.accordion-item');
    
    accordionItems.forEach(item => {
        const button = item.querySelector('.accordion-button');
        const content = item.querySelector('.accordion-collapse');

        button.addEventListener('click', () => {
            const isExpanded = button.getAttribute('aria-expanded') === 'true';
            
            // Close all other items and open the current one simultaneously
            accordionItems.forEach(otherItem => {
                const otherButton = otherItem.querySelector('.accordion-button');
                const otherContent = otherItem.querySelector('.accordion-collapse');
                
                if (otherItem === item) {
                    otherButton.setAttribute('aria-expanded', !isExpanded);
                    otherContent.style.transition = 'max-height 0.3s ease-in-out';
                    otherContent.style.maxHeight = !isExpanded ? `${otherContent.scrollHeight}px` : '0';
                    setTimeout(() => {
                        otherContent.classList.toggle('show');
                    }, 300);
                } else {
                    otherButton.setAttribute('aria-expanded', 'false');
                    otherContent.style.transition = 'max-height 0.3s ease-in-out';
                    otherContent.style.maxHeight = '0';
                    setTimeout(() => {
                        otherContent.classList.remove('show');
                    }, 300);
                }
            });
        });
    });
}

export function initGoogleCalendarButtons() {
    // Load CSS
    const link = document.createElement('link');
    link.href = 'https://calendar.google.com/calendar/scheduling-button-script.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Load JS
    const script = document.createElement('script');
    script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = function() {
        const targetAlex = document.getElementById('hours-card-0');
        const targetAlica = document.getElementById('hours-card-1');

        if (targetAlex) {
            calendar.schedulingButton.load({
                url: 'https://calendar.google.com/calendar/appointments/AcZssZ1p6EAwsQnitAElb8e2SZhiGrk-WJDw8Ks3W48=?gv=true',
                color: '#161616',
                label: 'Book a session',
                target: targetAlex,
            });
        }

        if (targetAlica) {
            calendar.schedulingButton.load({
                url: 'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3WtA_53Zig9Gdwu50s-LXGDE-9mbmBo-j2nHo=?gv=true',
                color: '#161616',
                label: 'Book a session',
                target: targetAlica,
            });
        }
    };
}

export function initHours() {
    initAccordions();
    initGoogleCalendarButtons();
}